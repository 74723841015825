import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'

import SecurityScanner from './components/securityScanner'

import { popupArr } from './arrs'

export default {
  name: 'step1',
  components: {
    SecurityScanner,
  },
  setup(props, ctx) {
    const securityScanner = ref(false)
    const disabled = ref(false)

    const capsuleList = ref([
      {
        title: 'Обновления', img: 'cloud', currentNum: 0, key: 'updateSystem',
      },
      {
        title: 'Offices', img: 'office', currentNum: 4, key: 'updateOffice',
      },
      {
        title: 'Антивирус', img: 'antivirus', currentNum: 2, key: 'updateAntivirus',
      },
    ])

    const popups = ref([])

    pushPopup(popupArr, popups.value)

    const capsuleListChange = (key) => {
      capsuleList.value.forEach((e) => {
        if (e.key === key.keyName) e.currentNum = 0
      })
    }

    const handleSuccess = () => {
      popups.value = []
      disabled.value = true
      setTimeout(() => {
        popups.value.push(
          { text: 'Отлично! Ты устранил все проблемы, связанные с безопасностью этого компьютера.' },
          { text: 'Вероятнее всего, вредоносный код смог запуститься на компьютере благодаря неисправленной ошибке в операционной системе!', buttonTitle: 'ПРОДОЛЖИТЬ' },
        )
      }, 0)
    }

    const handleErrorScanner = () => {
      popups.value.push({
        text: 'Это не самое важное обновление, найди более критичное.',
      })
    }

    const handleMessage = (mess) => {
      // popups.value = []
      setTimeout(() => popups.value.push(mess), 0)
    }

    const nextStep = () => {
      ctx.root.$router.push('/education1/lesson4')
    }

    const openScanner = () => {
      if (securityScanner.value) return

      securityScanner.value = true
      popups.value = []
      pushPopup([
        { text: 'Ого! Сколько тут неправильных настроек! Давай их исправим.' },
        { text: 'Выбери настройки, которые нужно исправить в первую очередь (влияют на безопасность компьютера).' },
      ], popups.value)
    }

    return {
      securityScanner,
      capsuleList,
      popups,
      disabled,
      capsuleListChange,
      handleSuccess,
      handleErrorScanner,
      handleMessage,
      nextStep,
      openScanner,
    }
  },
}
