export const popupsArr = [
  { text: 'Отлично! Теперь мы поняли, как вирус смог запуститься на компьютере, и обновили программное обеспечение, чтобы злоумышленники не смогли воспользоваться ошибками в операционной системе!', buttonTitle: 'ПРОДОЛЖИТЬ' },
]

export const arrSecurityScanner = [
  {
    warning: true, btnText: 'Критичная', text: 'Обновление антивирусных баз', svg: 'downFile', key: 'updateAntivirus', update: false,
  },
  {
    recommend: true, btnText: 'Рекомендация', svg: 'downFile', text: 'Обновление Game Rstars', key: 'wrong1',
  },
  {
    warning: true, btnText: 'Критичная', text: 'Обновление офисных программ', svg: 'downFile', key: 'updateOffice', update: false,
  },
  {
    recommend: true, btnText: 'Рекомендация', svg: 'downFile', text: 'Обновление программы Калькулятор', key: 'wrong2',
  },
  {
    recommend: true, btnText: 'Рекомендация', svg: 'downFile', text: 'Обновление программы Погода', key: 'wrong3',
  },
  {
    recommend: true, btnText: 'Рекомендация', svg: 'downFile', text: 'Обновление программы Карты', key: 'wrong4',
  },
  {
    recommend: true, btnText: 'Рекомендация', svg: 'downFile', text: 'Обновление программы Фото', key: 'wrong5',
  },
  {
    warning: true, btnText: 'Критичная', text: 'Обновление операционной системы', svg: 'downFile', key: 'updateSystem', update: false,
  },
]

export const successArr = {
  updateSystem: { text: 'Верно! Обновлять операционную систему необходимо, так как с обновлением могут появляться не только новые функции, но и «заплатки» безопасности, в которых устраняют уязвимости.' },
  updateAntivirus: { text: 'Точно! Каждый антивирус имеет базу вредоносного программного обеспечения. Важно поддерживать базу в актуальном состоянии.' },
  updateOffice: { text: 'Правильно! Если в офисном приложении не исправлена уязвимость, то хакер может активировать вирус, используя уязвимость в таких программах и других подобных.' },
}
