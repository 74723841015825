import {
  ref,
} from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'

import { arrSecurityScanner, popupsArr, successArr } from './arrs'
import UpdateSystem from './components/updateSystem'
import UpdateAntivirus from './components/updateAntivirus'
import UpdateOffice from './components/updateOffice'

import LoadWindow from './components/loadWindow'

export default {
  name: 'securityScanner',
  props: {
    disabled: Boolean,
  },
  components: {
    UpdateSystem,
    UpdateAntivirus,
    LoadWindow,
    UpdateOffice,
  },

  setup(props, ctx) {
    const { emit } = ctx

    const active = ref(false)

    const securityScannerProxy = ref(arrSecurityScanner)

    const step = ref(0)
    const popups = ref([])


    const popupName = ref('')

    const loadWindow = ref(false)
    const updateSystem = ref(false)

    const changePopupName = (item) => {
      if (item.key.indexOf('wrong') !== -1) {
        emit('error')
        return
      }
      if (!item.update) {
        popupName.value = item.key
        emit('message', successArr[item.key])
      }
      // popupName.value = item.key
    }

    const loadFinish = (val) => {
      emit('capsuleListCurrentNum', val)
      loadWindow.value = false
      popupName.value = ''
      const arrUpdate = []
      securityScannerProxy.value.forEach((e) => {
        if (val.keyName === e.key) {
          e.update = true
        }
        if (e.key && e.key.indexOf('wrong') === -1) {
          arrUpdate.push(e)
        }
      })
      if (arrUpdate.every(e => e.update)) {
        emit('success')
        pushPopup(popupsArr, popups.value)
      }
    }

    return {
      active,
      step,
      popupName,
      updateSystem,
      loadWindow,
      securityScannerProxy,
      popups,
      changePopupName,
      loadFinish,
    }
  },
}
